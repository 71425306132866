import React from "react"
import { IntlProvider } from "gatsby-plugin-intl"

import Layout from "../../components/site/layout"
import Seo from "../../components/common/seo"
import {
  getLocale,
  getPathName,
  messagesForLanguage,
} from "../../utils/localized"
import {
  professionalsCategory,
  tiersCategories,
} from "../../components/pages/pricing/content"
import PricingContent from "../../components/pages/pricing/page"
import { Section } from "../../components/common/layout"
import LaunchBlock from "../../components/blocks/launch"

const PricingPage = () => (
  <Layout>
    <PricingContent
      categories={tiersCategories}
      defaultCategory={professionalsCategory}
      sections={
        <Section t-xlg h-centered>
          <LaunchBlock />
        </Section>
      }
    />
  </Layout>
)

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

export default PricingPage
